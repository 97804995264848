/**
 * @returns {string}
 */
export function webVitalsScript () {
  return `
    (function () {
      window.webVitalsLogged = false
      window.webVitalsLoaded = []
      window.webVitalsResult = {}

      function report ({ name, rating, value, entries }) {
        name = name.toLowerCase()

        if (
          window.webVitalsLogged ||
          window.webVitalsLoaded.includes(name)
        ) {
          return
        }

        window.webVitalsResult[name] = {
          rating,
          value,
          entries
        }

        window.webVitalsLoaded.push(name)

        if (window.webVitalsLoaded.length === 4) {
          console.log('WEB_VITALS', window.webVitalsResult)
          window.webVitalsLogged = true
        }
      }

      const script = document.createElement('script')
      script.src = 'https://unpkg.com/web-vitals@4/dist/web-vitals.attribution.iife.js'
      script.onload = function () {
        webVitals.onCLS(report, { reportAllChanges: true })
        webVitals.onFCP(report, { reportAllChanges: false })
        webVitals.onLCP(report, { reportAllChanges: true })
        webVitals.onTTFB(report, { reportAllChanges: false })
      }

      document.head.appendChild(script)
    })()
  `
}
