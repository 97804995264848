import { CONSTANTS, parseTracking, size } from '@ocp-zmarta/zmarta-cl'

/**
 * @param {string} brand
 * @param {string} market
 * @param {boolean} whiteLabel
 * @param {object} fields
 * @param {object} form
 * @param {object} tracking
 * @returns {*}
 */
export function setTracking ({ brand, market, whiteLabel = false, fields = {}, form = {}, tracking = {} } = {}) {
  if (!market) return fields

  const affId = form?.global?.affId?.value
  const brokerId = form?.global?.brokerId?.value
  const mediaBrokerId = form?.global?.mediaBrokerId?.value
  const constants = CONSTANTS[market?.toUpperCase()]

  if (!size(fields) && !size(form) && !size(tracking)) return fields

  tracking.brokerId = tracking.brokerId || brokerId
  tracking.affId = tracking.affId || affId
  tracking.mediaBrokerId = tracking.mediaBrokerId || mediaBrokerId || tracking.brokerId || brokerId

  // If brandId is Zmarta and brokerId/mediaBrokerId is Broker then set correct brokerId/mediaBrokerId
  if (brand === 'zmarta') {
    if (tracking.brokerId === constants.BRANDS.BROKER.BROKER_ID) tracking.brokerId = constants.BRANDS.ZMARTA.BROKER_ID
    if (tracking.mediaBrokerId === constants.BRANDS.BROKER.BROKER_ID) tracking.mediaBrokerId = constants.BRANDS.ZMARTA.BROKER_ID
  }

  // If brandId is Broker and brokerId/mediaBrokerId is Zmarta then set correct brokerId/mediaBrokerId
  if (brand === 'broker') {
    if (tracking.brokerId === constants.BRANDS.ZMARTA.BROKER_ID) tracking.brokerId = constants.BRANDS.BROKER.BROKER_ID
    if (tracking.mediaBrokerId === constants.BRANDS.ZMARTA.BROKER_ID) tracking.mediaBrokerId = constants.BRANDS.BROKER.BROKER_ID
  }

  // Set brokerId, mediaBrokerId and affId from form
  if (whiteLabel) {
    tracking.brokerId = brokerId
    tracking.affId = affId
    tracking.mediaBrokerId = mediaBrokerId || brokerId

    if (fields?.whiteLabel) {
      fields.whiteLabel.bidId = tracking.bidId
    }
  }

  // remove gaClickId if it's older than max age
  if (tracking.gaClickId && tracking.gaClickIdDate) {
    const diffInTime = new Date().getTime() - new Date(tracking.gaClickIdDate).getTime()
    const diffInDays = diffInTime / (1000 * 3600 * 24)

    if (diffInDays > 90) tracking.gaClickId = undefined
  }

  // remove gaClickIdDate
  delete tracking.gaClickIdDate

  fields.tracking = parseTracking(tracking)

  return fields
}
