import { getElement, getUrlSearchParams, hasProp } from '@ocp-zmarta/zmarta-cl'
import Vue from 'vue'
import VueScrollTo from 'vue-scrollto'
import requests from '../../requests'

export default {
  namespaced: true,

  // ? ******************************************************************************************
  // ?  State
  // ? ******************************************************************************************

  state: {
    internalAccess: false,
    isMobile: true,
    isTablet: false,
    isDesktop: false,
    scroll: 0
  },

  // ? ******************************************************************************************
  // ?  Getters
  // ? ******************************************************************************************

  getters: {
    getMisc: state => state,
    getInternalAccess: state => state.internalAccess,
    getIsMobile: state => state.isMobile,
    getIsTablet: state => state.isTablet,
    getIsDesktop: state => state.isDesktop,
    getScroll: state => state.scroll
  },

  // ? ******************************************************************************************
  // ?  Actions
  // ? ******************************************************************************************

  actions: {
    setMisc ({ commit }, payload) {
      commit('mutateMisc', payload)
    },

    // * **************************************************************************************
    // * **************************************************************************************

    scrollToElement (_, { element, offset = 0 }) {
      if (!element) return
      if (!getElement(element)) return

      let headerOffset = 0
      const header = getElement('#zmarta-header')

      if (header) {
        headerOffset = header.offsetHeight
      }

      VueScrollTo.scrollTo(element, 500, { offset: (offset - headerOffset) || -20 })
      return true
    },

    // * **************************************************************************************
    // * **************************************************************************************

    async setInternalAccess ({ state, commit }) {
      if (state.internalAccess) return

      try {
        let internalAccess = hasProp(getUrlSearchParams(), 'testcafe')

        if (!internalAccess) {
          const response = await requests.internal.internalAccess()
          internalAccess = response?.internalAccess
        }

        commit('mutateMisc', { internalAccess })

        return true
      } catch (e) {
        commit('mutateMisc', { internalAccess: false })

        return false
      }
    }
  },

  // ? ******************************************************************************************
  // ?  Mutations
  // ? ******************************************************************************************

  mutations: {
    mutateMisc (state, payload) {
      if (hasProp(payload, 'internalAccess')) { Vue.set(state, 'internalAccess', payload.internalAccess) }
      if (hasProp(payload, 'isMobile')) { Vue.set(state, 'isMobile', payload.isMobile) }
      if (hasProp(payload, 'isTablet')) { Vue.set(state, 'isTablet', payload.isTablet) }
      if (hasProp(payload, 'isDesktop')) { Vue.set(state, 'isDesktop', payload.isDesktop) }
      if (hasProp(payload, 'scroll')) { Vue.set(state, 'scroll', payload.scroll) }
    }
  }
}
