import { request } from '@ocp-zmarta/zmarta-cl'

/**
 * @returns {Promise<any>}
 */
export async function internalAccess () {
  return request({
    method: 'GET',
    url: '/cl-form/internal-access'
  })
}
